import React from "react";
import BookParty from "./buttons/BookParty";
import { Link } from "react-router-dom";

const Planning = ({ hText, pText, bText, imagePicture, voucher }) => {
  return (
    <div className="flex md:flex-row flex-col items-center md:m-[80px] my-10 mx-4 text-[#34004A] md:gap-16 gap-10">
      <img src={imagePicture} alt="" className="md:w-[45%]" />
      <div className="">
        <h1 className="font-black md:text-5xl text-2xl md:leading-tight mb-4">
          {hText}
        </h1>
        <p className="font-raleway md:mb-10 mb-8 font-semibold md:w-[80%]">
          {pText}
        </p>
        {voucher ? (
          <div className="flex items-center gap-4">
            <a
              href="https://giftup.app/place-order/e2ecd5bb-90b5-4735-13f0-08dcfdb7b75c?platform=hosted&language=en-GB"
              target="_blank"
              rel="noreferrer"
              className="bg-custom-gradient border border-1 border-[#A80099] py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
            >
              BUY VOUCHER
            </a>
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <BookParty />
            <a
              href="https://go.funl.ink/J5XMkfn0"
              target="_blank"
              rel="noreferrer"
              className="bg-transparent border border-1 border-[#A80099] py-2 px-6 rounded-full text-[#A80099] font-bold md:text-base text-sm"
            >
              BOOK GAMES
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Planning;
