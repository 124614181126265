import React from 'react'
import { Link } from 'react-router-dom'

const BookParty = () => {
  return (
    <a
    href="https://go.funl.ink/uDiXaiAA"
    target="_blank"
    rel="noreferrer"      
      className="bg-custom-gradient border border-1 border-[#A80099] py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
    >
      BOOK PARTY
    </a>
  )
}

export default BookParty