import React, { useState } from "react";
import { navLinks } from "../constants";
import { Link, NavLink } from "react-router-dom";
import { closeIcon, heroLogo, menuIcon, navbarLogo } from "../assets";

const NewNavbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="bg-black py-4 md:px-[80px] px-4 flex justify-between items-center fixed top-0 left-0 right-0 z-50 shadow-[#86007199] shadow-lg">
      <Link to="/">
        <img src={heroLogo} alt="" className="md:h-[50px] h-10 animate-scaleUpDown" />
      </Link>
      <div className="md:flex items-center text-white text-sm gap-6">
        {navLinks.map((item) => (
          <NavLink
            to={item.linkRoute}
            className="hover:text-gray-300 hidden md:block"
            style={({ isActive }) => {
              return {
                color: isActive ? "#7F00B3" : "",
              };
            }}
          >
            {item.linkName}
          </NavLink>
        ))}
      </div>
      <a
        href="https://go.funl.ink/J5XMkfn0"
        target="_blank"
        rel="noreferrer"
        className="md:block hidden bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
      >
        BOOK NOW
      </a>
      {/* <a
        href="https://alpha.togo.uk.com/booking/v4/activity.aspx?venueid=2659&nv=true&mode=choose"
        target="_blank"
        rel="noreferrer"
        className="md:block hidden bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm"
      >
        BOOK NOW
      </a> */}

      <div className="block md:hidden">
        <img
          src={toggle ? closeIcon : menuIcon}
          className="md:hidden block h-10 w-10 cursor-pointer"
          alt=""
          onClick={() => setToggle(!toggle)}
        />
        <div
          className={`${
            toggle ? "flex" : "hidden"
          } px-4 py-10 h-[95vh] bg-black bg-opacity-100 text-white absolute top-20 right-0 -my-2 w-full`}
        >
          <ul className="list-none flex flex-col justify-between pb-10 w-full">
            {navLinks.map((item) => (
              <div className="relative group/item pb-6 border-b w-full">
                <div className="cursor-pointer">
                  <NavLink
                    to={item.linkRoute}
                    className="group-hover/item:text-[#7F00B3]"
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7F00B3" : "",
                      };
                    }}
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    {item.linkName}
                  </NavLink>
                </div>
              </div>
            ))}
            <div 
              className="flex flex-col items-center w-full">
              {/* <Link
                to="/booking"
                className="bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm w-full text-center"
                onClick={() => {
                  setToggle(false);
                }}
              >
                BOOK NOW
              </Link> */}
              <a
                href="https://go.funl.ink/J5XMkfn0"
                target="_blank"
                rel="noreferrer"
                className="bg-custom-gradient py-2 px-6 rounded-full text-white font-bold md:text-base text-sm w-full text-center"
              >
                BOOK NOW
              </a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewNavbar;
