import React from "react";
import NewHeroSection from "../components/NewHeroSection";
import ClickHere from "../components/ClickHere";
import Games from "../components/Games";
import Events from "../components/Events";
import VrGames from "../components/VrGames";
import Location2 from "../components/Location2";
import Testimonials from "../components/Testimonials";
import SliderImages from "../components/SliderImages";

const HomePage = () => {

  return (
    <div className="">
      {/* <HeroSection /> */}
      <NewHeroSection />
      <ClickHere />
      <SliderImages />
      <Games />
      <Events />
      <VrGames />
      <Location2 />
      {/* <Testimonials /> */}
    </div>
  );
};

export default HomePage;
